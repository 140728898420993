import { useMaestroToast } from '@maestro/components';
import {
  CreateFactoryErrorCode,
  StoryFactory,
  useCreateStoryFactoryMutation,
} from '@maestro/graphql';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StoryFactoryForm } from './StoryFactoryForm';

export const StoryFactoryCreate: React.FC = () => {
  const { mutateAsync: createFactory } = useCreateStoryFactoryMutation();
  const [isSaving, setSaving] = useState(false);
  const toast = useMaestroToast();
  const navigate = useNavigate();

  const save = async (
    factory: Pick<
      StoryFactory,
      | 'prompt'
      | 'fields'
      | 'title'
      | 'jsonSchema'
      | 'systemPrompt'
      | 'promptSlug'
    >,
  ) => {
    const { prompt, fields, title, jsonSchema, systemPrompt, promptSlug } =
      factory;
    const factoryStrategy = 'v1'; // TODO fix this while we don't support others

    setSaving(true);
    try {
      const result = await createFactory({
        input: {
          title,
          prompt,
          fields,
          factoryStrategy,
          jsonSchema,
          systemPrompt,
          promptSlug,
        },
      });

      if (result.createFactory.error) {
        const [error] = result.createFactory.error;

        toast({
          title:
            error.code === CreateFactoryErrorCode.InvalidJsonSchema
              ? 'JSON'
              : 'Failed to create factory',
          description: error.message,
          status: 'warning',
        });
      } else {
        toast({ title: 'Factory saved', status: 'success' });
      }

      if (result.createFactory.storyFactory?.id) {
        toast({ title: 'Factory created', status: 'success' });
        navigate(`/story-factory/${result.createFactory.storyFactory.id}`);
      }
    } finally {
      setSaving(false);
    }
  };

  return (
    <StoryFactoryForm
      formTitle="Creating Story Factory"
      onSave={save}
      onCancel={() => navigate('/story-factory')}
      isSaving={isSaving}
    />
  );
};
